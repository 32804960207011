import Column from 'components/column';
import React from 'react';
import Hero from 'sections/hero';

import * as styles from './notfound.module.scss';

const NotFoundPage: React.FC = () => {
  return (
    <Hero>
      <Column className={styles.heroContainer}>
        <h1>{'404'}</h1>
        <h2>Page not found</h2>
      </Column>
    </Hero>
  );
};

export default NotFoundPage;
